<template>
  <div class="info-intro-container center">

    <div class="info-intro-container-background" />
    <img class="info-intro-container-image top" :src="welcomeImage"/>
    <img class="info-intro-container-image bottom" :src="welcomeImage"/>

    <div class="info-intro column">
      <span class="info-intro-title">
        <p class="info-intro-title-text">학교장 인사말</p>
      </span>

      <span class="info-intro-content column">
        <p class="info-intro-content-text">안녕하세요? 한선생직업전문학교에 오신 것을 환영합니다.</p>
        <p class="info-intro-content-text">미래사회를 움직이는 진정한 힘은 인간으로서의 가치로운 신념과 창조적 기술입니다.</p>
      </span>

      <span class="info-intro-content">
        <p class="info-intro-content-text">저희 한선생 직업전문학교는 요리, 제과제빵, 바리스타 전문 평생직업교육기관으로서</p>
        <p class="info-intro-content-text">시대가 요구하는 실력있는 전문기술인 양성을 목표로,</p>
        <p class="info-intro-content-text">묵묵히 진실하게 교육기관의 사명감을 실천합니다.</p>
      </span>

      <span class="info-intro-content">
        <p class="info-intro-content-text">기술에 대한 배움의 열정과 신념만 있다면 도전하십시오.</p>
        <p class="info-intro-content-text">기본에 충실한 최상의 교육 서비스를 약속드립니다.</p>
      </span>

        <span class="info-intro-content">
        <p class="info-intro-content-text">여러분의 꿈과 희망을 비추는 환한 등불이 되겠습니다.</p>
        <p class="info-intro-content-text">감사합니다.</p>
      </span>

      <span class="info-intro-content">
        <p class="info-intro-sign">한선생직업전문학교 교장 <img :src="signature"></p>
      </span>
    </div>
  </div>
</template>

<script>
import signature from '../../assets/images/signature.png'
import welcomeImage from '../../assets/images/welcome.jpg'
export default {
  data() {
    return {
      signature,
      welcomeImage
    }
  }
}
</script>

<style>

</style>
